.cardWrapper {
      padding: 10px;
      overflow: hidden;
      max-width: 100%;
      width: calc(40vh * 0.56);
      height: 40vh;
      background-size: cover;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
      align-content: space-between;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
    }

    .halfContainer{
          height: 50%;
          width: 100%;
          overflow: hidden;
         
    }
.btnContainer{
      align-items: flex-end;
      display: flex;
}
.name {
      font-family: Impact, sans-serif;
      font-size: 2em;
      color: #fff;
      text-shadow: #000 0px 0px 6px;
      line-height: 1.5em;
      height: 5em;       /* height is 2x line-height, so two lines will display */      
      width:100%;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
   }    
    