.buttonLove {
      background-color: red;
      border: none;
      color: white;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin: 4px 2px;
      border-radius: 50%;
      height: 8vh;
      width: 8vh;
      border: solid 1px #555;
      box-shadow: -2px 2px  rgba(0,0,0,0.6);
      -moz-box-shadow: -2px 2px  rgba(0,0,0,0.6);
      -webkit-box-shadow: -2px 2px  rgba(0,0,0,0.6);
      -o-box-shadow: -2px 2px  rgba(0,0,0,0.6);
      border-radius:30px;
    }
    .buttonLoveActive{ 
      background-color: red;
      border: none;
      color: white;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin: 4px 2px;
      border-radius: 50%;
      height: 8vh;
      width: 8vh;
      border: solid 0px #555;
      box-shadow: 0
}
/* Ribbon */
